import React from 'react'
import TransitionLink from "../components/transition-link"

export default ({ project }) => (
  <li className="g-projects__project" style={{opacity: 0}}>
  	<div className="g-projects__image">
	    <img 
        src={`${project.thumbnailImage.file.url}?fit=pad&w=900`}
        srcSet={`${project.thumbnailImage.file.url}?fit=pad&w=1200 1200w, ${project.thumbnailImage.file.url}?fit=pad&w=600 600w`}
        />
	    {
        project.hoverThumbnailImage &&
        <img className="g-projects__hover-image"
          src={`${project.hoverThumbnailImage.file.url}?fit=pad&w=600`}
          srcSet={`${project.hoverThumbnailImage.file.url}?fit=pad&w=1200 1200w, ${project.hoverThumbnailImage.file.url}?fit=pad&w=600 600w`}
          />
      }
  	</div>
    <p className="o-title-small g-projects__title">{project.descriptiveTitle}</p>
    <h2 className="g-projects__client">
      <TransitionLink to={`/cases/${project.slug}`} tiny={false} label={project.clientName} />
    </h2>
  </li>
)
