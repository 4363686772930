import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Layout from '../components/layout'
import CardProject from '../components/card-project'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

class RootIndex extends React.Component {
  constructor (props) {
    super(props);
    this.elProjects = React.createRef();
  }

  onProjectLoaded (project) {
    gsap.fromTo(project, {
      opacity:0,
      y: 50
    }, {
      opacity: 1,
      y: 0,
      duration: 0.4,
      scrollTrigger: {
        once: true,
        trigger: project,
        start: 'top 75%'
      }
    });
  }

  componentDidMount () {
    /* GSAP SCROLL EFFECT */
    gsap.registerPlugin(ScrollTrigger);
    this.elProjects.current.querySelectorAll('li').forEach(li => {
      const img = li.querySelector('img');
      if (img.complete) {
        this.onProjectLoaded(li);
      } else {
        img.onload = () => this.onProjectLoaded(li);
      }
    });
  }

  render() {
    const intro = get(this, 'props.data.contentfulHomepage.introductionText.json');
    const outro = get(this, 'props.data.contentfulHomepage.extroductionText.json');
    let projects = get(this, 'props.data.allContentfulCase.edges');
    projects = projects.sort((a, b) => a.node.order - b.node.order);
    let showIntro = true;
    if (intro.content.length === 1) {
      if (intro.content[0].content[0].value === ' ') {
        showIntro = false;
      }
    }
    let showOutro = true;
    if (outro.content.length === 1) {
      if (outro.content[0].content[0].value === ' ') {
        showOutro = false;
      }
    }

    const options = {
      renderText: (text) => {
        return text
          .replace(/\u2028/g, "")
          .split("\n")
          .flatMap((text, i) => [i > 0 && <br key={i} />, text])
      }
    }

    return (
      <Layout location={this.props.location} title="Lotte Bijlsma">
        <main className="g-home l-wrapper">
          {showIntro ? <div className="g-home__intro o-title-large o-rich-text">{documentToReactComponents(intro, options)}</div> : ''}

          <ul className="g-projects" ref={this.elProjects}>
            {projects.map((project, index) => {
              return <CardProject key={index} project={project.node} />
            })}
          </ul>

          {showOutro ? <div className="g-home__outro o-title-large o-rich-text">{documentToReactComponents(outro, options)}</div> : ''}
          
        </main>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    contentfulHomepage {
      introductionText {
        id
        json
      }
      extroductionText {
        id
        json
      }
    }
    allContentfulCase {
      edges {
        node {
          descriptiveTitle
          clientName
          slug
          order
          hoverThumbnailImage {
            file {
              url
            }
          }
          thumbnailImage {
            file {
              url
            }
          }
        }
      }
    }
  }
`
